import React from 'react';
import { Modal } from 'antd';
import './modal.scss';

export const Simplemodal = props => {
  return (
    <Modal
      closable={props.closable ? false : true}
      keyboard={props.closable ? false : true}
      cancelButtonProps={{ style: { display: 'none' } }}
      open={props.open}
      onCancel={props.onClick}
      destroyOnClose
      footer=""
      className="new-modal"
      centered={true}
      okButtonProps={{ style: { display: 'none' } }}
      styles={{
        mask: {
          background: 'rgba(103, 169, 72, 0.2)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      {props.children}
    </Modal>
  );
};

export const SimpleCancelmodal = props => {
  return (
    <Modal
      closable={props.closable ? false : true}
      keyboard={props.closable ? false : true}
      cancelButtonProps={{ style: { display: 'none' } }}
      open={props.open}
      onCancel={props.onClick}
      destroyOnClose
      footer=""
      className="newCancel-modal"
      centered={true}
      okButtonProps={{ style: { display: 'none' } }}
      styles={{
        mask: {
          background: 'rgba(103, 169, 72, 0.2)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      {props.children}
    </Modal>
  );
};

export const Largemodal = props => {
  return (
    <Modal
      closable={props.closable ? false : true}
      keyboard={props.closable ? false : true}
      cancelButtonProps={{ style: { display: 'none' } }}
      open={props.open}
      onCancel={props.onClick}
      destroyOnClose
      footer=""
      className="large-modal"
      centered={true}
      okButtonProps={{ style: { display: 'none' } }}
      styles={{
        mask: {
          background: 'rgba(103, 169, 72, 0.2)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      {props.children}
    </Modal>
  );
};

export const LargemodalCustomCancel = props => {
  return (
    <Modal
      closable={props.closable ? false : true}
      keyboard={props.closable ? false : true}
      cancelButtonProps={{ style: { display: 'none' } }}
      open={props.open}
      onCancel={props.onClick}
      destroyOnClose
      footer=""
      className="large-modalcancel"
      centered={true}
      okButtonProps={{ style: { display: 'none' } }}
      styles={{
        mask: {
          background: 'rgba(103, 169, 72, 0.2)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      {props.children}
    </Modal>
  );
};

export const Videomodal = props => {
  return (
    <Modal
      closable={props.closable ? false : true}
      keyboard={props.closable ? false : true}
      cancelButtonProps={{ style: { display: 'none' } }}
      open={props.open}
      onCancel={props.onClick}
      destroyOnClose
      footer=""
      className="video-modal"
      centered={true}
      okButtonProps={{ style: { display: 'none' } }}
      styles={{
        mask: {
          background: 'rgba(103, 169, 72, 0.2)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      {props.children}
    </Modal>
  );
};

export const TourGuidemodal = props => {
  return (
    <Modal
      closable={props.closable ? false : true}
      keyboard={props.closable ? false : true}
      cancelButtonProps={{ style: { display: 'none' } }}
      open={props.open}
      onCancel={props.onClick}
      destroyOnClose
      footer=""
      className="tour-modal"
      centered={false}
      okButtonProps={{ style: { display: 'none' } }}
      styles={{
        mask: {
          background: 'rgba(103, 169, 72, 0.2)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      {props.children}
    </Modal>
  );
};

// export const ImageModal = props => {
//   return (
//     <Modal
//       closable={props.closable ? false : true}
//       keyboard={props.closable ? false : true}
//       cancelButtonProps={{ style: { display: 'none' } }}
//       open={props.open}
//       onCancel={props.onClick}
//       destroyOnClose
//       footer=""
//       className="new-modal"
//       centered={true}
//       okButtonProps={{ style: { display: 'none' } }}
//       styles={{
//         mask: {
//           background: 'rgba(103, 169, 72, 0.2)',
//           backdropFilter: 'blur(4px)',
//         },
//       }}
//     >
//       {props.children}
//     </Modal>
//   );
// };
