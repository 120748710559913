import React, { useState, useEffect, useRef } from 'react';
import './chat.scss';
import { BiCheckDouble } from 'react-icons/bi';
import { PostQuestion, ReferenceChat, ReplyChat } from './postQuestion';
import { BsReply } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { ImageCont } from './postQuestion';
import { CiFileOn } from 'react-icons/ci';
import { FaFilePdf } from 'react-icons/fa6';

export const ChatHistory = props => {
  const userD = useSelector(state => state.auth?.userData);
  const myMessages = useSelector(state => state.webVisaChat?.chats);

  const chatContainerRef = useRef(null);

  // console.log(myMessages);

  const addMessage = (text, isUser = true, replyRef) => {
    const date = new Date().toISOString();
    props.setMessages(prevMessages => [
      ...prevMessages,
      { text, isUser, date, replyRef },
    ]);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.newmsg, myMessages]);

  const formatDateTime = dateTimeString => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const todayDateTime = dateTimeString => {
    const date = new Date(dateTimeString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const groupedMessages = groupMessagesByDate(myMessages);

  // console.log(groupedMessages);

  return (
    <div
      style={{
        background: '#F7FFF3',
        width: '100%',
        height: '100%',
        border: '1px solid #eaecf0',
        position: 'relative',
      }}
    >
      <div className="VisaChatFeature__chatshist">
        <div
          className="VisaChatFeature__chatshist__scrollbar"
          style={{ height: '90%' }}
          ref={chatContainerRef}
        >
          {Object.keys(groupedMessages?.others).map((monthYear, index) => (
            <div key={index}>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '13px',
                  margin: '10px 0px',
                }}
              >
                {monthYear}
              </p>
              {groupedMessages.others[monthYear].map((msg, idx) =>
                msg?.creator === 'user' ? (
                  <UserMessage
                    key={idx}
                    msg={msg}
                    formatDateTime={formatDateTime}
                  />
                ) : (
                  <ReceivedMessage
                    key={idx}
                    msg={msg}
                    setReplyChat={props.setReplyChat}
                    formatDateTime={formatDateTime}
                  />
                ),
              )}
            </div>
          ))}
          {groupedMessages?.yesterday.length > 0 && (
            <>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '13px',
                  margin: '10px 0px',
                }}
              >
                Yesterday
              </p>
              {groupedMessages.yesterday.map((msg, index) =>
                msg?.creator === 'user' ? (
                  <UserMessage
                    key={index}
                    msg={msg}
                    formatDateTime={formatDateTime}
                  />
                ) : (
                  <ReceivedMessage
                    key={index}
                    msg={msg}
                    setReplyChat={props.setReplyChat}
                    formatDateTime={formatDateTime}
                  />
                ),
              )}
            </>
          )}

          {groupedMessages?.today.length > 0 && (
            <>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '13px',
                  margin: '10px 0px',
                }}
              >
                Today
              </p>
              {groupedMessages.today.map((msg, index) =>
                msg?.creator === 'user' ? (
                  <UserMessage
                    key={index}
                    msg={msg}
                    formatDateTime={todayDateTime}
                  />
                ) : (
                  <ReceivedMessage
                    key={index}
                    msg={msg}
                    setReplyChat={props.setReplyChat}
                    formatDateTime={todayDateTime}
                  />
                ),
              )}
            </>
          )}
        </div>

        {props.replyChat ? (
          <ReplyChat
            replyChat={props.replyChat}
            setReplyChat={props.setReplyChat}
          />
        ) : null}

        {props.selectedFile?.length > 0 ? (
          <ImageCont
            handleViewImage={props.handleViewImage}
            removeFile={props.removeFile}
            selectedFile={props.selectedFile}
            selectedFileBinary={props.selectedFileBinary}
          />
        ) : (
          <></>
        )}
        <div
          style={{
            height: '10%',
            border: '1px solid #EAECF0',
            background: 'white',
            position: 'absolute',
            bottom: '0px',
            width: '100%',
            left: '0px',
            padding: '10px 20px',
          }}
        >
          <PostQuestion
            addMessage={addMessage}
            replyChat={props.replyChat}
            setReplyChat={props.setReplyChat}
            handleSendMessage={props.handleSendMessage}
            inputValue={props.inputValue}
            setInputValue={props.setInputValue}
            onFileLoad={props.onFileLoad}
            handleIconClick={props.handleIconClick}
            fileInputRef={props.fileInputRef}
            handleFileIconClick={props.handleFileIconClick}
            handleFileChange={props.handleFileChange}
            ImgInputRef={props.ImgInputRef}
            handleImgUpload={props.handleImgUpload}
            selectedFile={props.selectedFile}
            processing={props.processing}
            setProcessing={props.setProcessing}
          />
        </div>
      </div>
    </div>
  );
};

const UserMessage = ({ msg, formatDateTime }) => (
  <div style={{ width: '100%' }} className="VisaChatFeature__chatshist__QA">
    <div className="VisaChatFeature__chatshist__QAContainer">
      {msg.quote && (
        <div
          className="VisaChatFeature__chatshist__replyRef"
          style={{ width: 'fit-content' }}
        >
          <p>{msg.quote}</p>
        </div>
      )}

      {msg.image ? (
        <img
          src={msg.image}
          alt=""
          style={{
            paddingBottom: '10px',
            height: '100%',
            maxHeight: '17rem',
            width: '100%',
          }}
        />
      ) : (
        <></>
      )}
      {msg.file ? (
        <div
          style={{ position: 'relative', cursor: 'pointer' }}
          onClick={() => {
            window.open(msg.file, '_blank');
          }}
        >
          <CiFileOn
            style={{
              width: '12rem',
              height: '12rem',
              color: '#d0d5dd',
            }}
          />
          <p
            style={{
              position: 'absolute',
              zIndex: '20',
              top: '50%',
              left: '4rem',
              fontWeight: '500',
              color: '#98A2B3',
              fontSize: '15px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '2px',
            }}
          >
            View File
            {msg.file.includes('pdf') ? (
              <p
                style={{
                  color: '#98A2B3',
                  fontSize: '12px',
                }}
              >
                pdf
              </p>
            ) : (
              // <FaFilePdf style={{ color: '#98A2B3', fontSize: '20px' }} />
              <></>
            )}
            {msg.file.includes('docx') ? (
              <p
                style={{
                  color: '#98A2B3',
                  fontSize: '12px',
                }}
              >
                Docx
              </p>
            ) : (
              // <FaFilePdf style={{ color: '#98A2B3', fontSize: '20px' }} />
              <></>
            )}
          </p>
        </div>
      ) : (
        <></>
      )}
      {msg.message}
    </div>

    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        color: '#98A2B3',
        fontSize: '12px',
        marginTop: '6px',
      }}
    >
      {formatDateTime(msg?.createdAt)} <BiCheckDouble />
    </div>
  </div>
);

const ReceivedMessage = ({ msg, setReplyChat, formatDateTime }) => (
  <div style={{ width: '100%' }} className="VisaChatFeature__chatshist__Ans">
    <BsReply
      className="VisaChatFeature__chatshist__Bsreply"
      onClick={() => setReplyChat(msg?.message)}
    />

    {/* <div className="VisaChatFeature__chatshist__AnsContainer">
      {msg.quote && (
        <div
          className="VisaChatFeature__chatshist__replyRef"
          style={{ width: 'fit-content' }}
        >
          <p>{msg.quote}</p>
        </div>
      )}

      {msg.image ? (
        <img src={msg.image} alt="" style={{ paddingBottom: '10px' }} />
      ) : (
        <></>
      )}

      {msg.message}
    </div> */}

    <div className="VisaChatFeature__chatshist__AnsContainer">
      {msg?.quote ? (
        <div
          className="VisaChatFeature__chatshist__replyRef"
          style={{ width: 'fit-content' }}
        >
          <p>{msg?.quote}</p>
        </div>
      ) : (
        <></>
      )}

      {msg?.image ? (
        <img
          src={msg?.image}
          alt=""
          style={{
            paddingBottom: '10px',
            height: '100%',
            maxHeight: '17rem',
            width: '100%',
          }}
        />
      ) : (
        <></>
      )}

      {msg?.file ? (
        <div
          style={{ position: 'relative', cursor: 'pointer' }}
          onClick={() => {
            window.open(msg?.file, '_blank');
          }}
        >
          <CiFileOn
            style={{
              width: '12rem',
              height: '12rem',
              color: '#d0d5dd',
            }}
          />
          <p
            style={{
              position: 'absolute',
              zIndex: '20',
              top: '50%',
              left: '4rem',
              fontWeight: '500',
              color: '#98A2B3',
              fontSize: '15px',
              // color: '#66AA48',
            }}
          >
            View File
          </p>
        </div>
      ) : (
        <></>
      )}
      {msg?.message}
    </div>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        color: '#98A2B3',
        fontSize: '12px',
        marginTop: '6px',
      }}
    >
      {formatDateTime(msg?.updatedAt || new Date().toISOString())}{' '}
      <BiCheckDouble />
    </div>
  </div>
);

const isSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const groupMessagesByDate = messages => {
  const groupedMessages = {
    today: [],
    yesterday: [],
    others: {},
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // console.log(messages);

  if (messages) {
    messages.forEach(message => {
      const messageDate = new Date(
        message?.createdAt || new Date().toISOString(),
      );

      if (isSameDay(messageDate, today)) {
        groupedMessages.today.push(message);
      } else if (isSameDay(messageDate, yesterday)) {
        groupedMessages.yesterday.push(message);
      } else {
        const monthYear = messageDate.toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        });
        if (!groupedMessages.others[monthYear]) {
          groupedMessages.others[monthYear] = [];
        }
        groupedMessages.others[monthYear].push(message);
      }
    });
  }

  return groupedMessages;
};
