import React, { useState, useEffect } from 'react';
import api from 'appRedux/api';
import { navigate } from '@reach/router';
import '../../Admin.css'
import { useUserData } from 'helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { Newpost } from 'components/common/notifybox/newpost';
import duummyAvatar from '../../../../assets/dummy-avatar.png';
import Loader from 'components/Loader/Loader';
import { TbMessage2 } from "react-icons/tb"; 
import { getFriendsFeedsDetails } from 'appRedux/actions/Feedschat';

const OtherGroupsMembers = props => {
  const [groupPost, setGroupPost] = useState([]);
  const { userData } = useUserData();
  const [isloading, setIsloading] = useState(false);

  const userD = useSelector(state => state.auth?.userData); 
  // console.log( props.userD?.id);

  useEffect(() => {
    setIsloading(true);
    const fetchData = async () => {
      try {
        const response = await api.get(
          `/group/messages-and-comments?groupId=${props.groupId}`,
        );
        const data = await response.data.data.data;
        setGroupPost(data);
        setIsloading(false);
      } catch (error) {
        // Handle error
        setIsloading(false);
        console.error(error);
      }
    };

    fetchData();
  }, [props.groupId]);

  var onScroll = () => {
    // const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
    const scrollTop = scrollRef.current.scrollTop;
    setScroll(scrollTop);
  };

  const [scroll, setScroll] = useState();
  const scrollRef = useRef(null);

  const uniqueUserData = Array.from(
    new Set(groupPost?.map(obj => obj?.creator?.username)),
  ).map(username => {
    return groupPost?.find(obj => obj?.creator?.username == username);
  });

  console.log(groupPost);
  // console.log(post?.userId);
  // console.log(props.userD);

  const dispatch = useDispatch();

  const handleIconClick = (userId, fullname, profilepic) => {
    localStorage.setItem('FriendUserName', fullname)
    localStorage.setItem('FriendProfilepic', profilepic)
    dispatch(getFriendsFeedsDetails(fullname, profilepic))
    navigate(`/feeds-chat/${userId}`);
  };
  console.log("Unique", uniqueUserData);

  //   const uniqueUserData = Array.from(
  //     new Set(
  //       groupPost.map(({ sender }) => {
  //         const fullname = `${sender?.firstName}  ${sender?.lastName}`;
  //         return fullname;
  //       }),
  //     ),
  //   ).map(fullName => {
  //     return groupPost.find(fullname => fullname === fullName);
  //   });

  return (
    <div
      className="groupMemContainer"
      // style={{ paddingRight: '3rem', paddingLeft: '3rem', paddingTop: '1rem' }}
    >
      {isloading ? (
        <Loader />
      ) : (
        <div className="" style={{ position: 'relative' }}>
          {uniqueUserData.map((post, index) => {
            console.log(post);

            if (post?.creator?.username?.includes('null')) {
              return '';
            } else {
              return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.5rem',
                  }}
                >
                  <img
                    src={
                      post?.creator?.profilePictureURL !== null
                        ? post?.creator?.profilePictureURL
                        : duummyAvatar
                    }
                    alt="proflie"
                    className="single-vesti-post-img"
                  />

                  <div style={{ fontWeight: 500 }}>
                    {' '}
                    {post?.creator?.firstName} {post?.creator?.lastName}
                  </div>
                </div>
                {/* <TbMessage2/> */}
                {post?.creator?.id ===  userD?.id ? '' : <TbMessage2 onClick={() => handleIconClick(post?.creator?.id, `${post?.creator?.firstName} ${post?.creator?.lastName}`, post?.creator?.profilePictureURL)} className='memMesIcon' /> }
                {/* {post?.userId ===  props.userD?.id? '' : <TbMessage2 onClick={() => handleIconClick(post?.creator?.id, `${post?.creator?.firstName} ${post?.creator?.lastName}`, post?.creator?.profilePictureURL)} className='memMesIcon' /> } */}
               
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
export default OtherGroupsMembers;
