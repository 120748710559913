import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import '../Admin.css';
// import { navigate } from '@reach/router';
import { useState, useRef } from 'react';
import duummyAvatar from '../../../assets/dummy-avatar.png';
import { TbMessage2 } from "react-icons/tb"; 
import { useDispatch } from 'react-redux';
import { getFriendsFeedsDetails } from 'appRedux/actions/Feedschat';

// eslint-disable-next-line
const GroupMembers = (props) => {



  const posts = useSelector(state => state.posts.data);
// eslint-disable-next-line
  var onScroll = () => {
    const scrollTop = scrollRef.current.scrollTop;
    setScroll(scrollTop);
  };
// eslint-disable-next-line
  const [scroll, setScroll] = useState();
  const scrollRef = useRef(null);

  // var myReminder = localStorage.getItem('memberReminder');

  // console.log(posts);

  const uniqueUserData = Array.from(
    new Set(posts.map(obj => obj?.creator?.username)),
  ).map(username => {
    return posts.find(obj => obj?.creator?.username === username);
  });

  const dispatch = useDispatch();

  const handleIconClick = (userId, fullname, profilepic) => {
    localStorage.setItem('FriendUserName', fullname)
    localStorage.setItem('FriendProfilepic', profilepic)
    dispatch(getFriendsFeedsDetails(fullname, profilepic))
    navigate(`/feeds-chat/${userId}`);
  };
  console.log("Unique", uniqueUserData);

  

  return (
    <div
      className="groupMemContainer"
    >
      <div className="" style={{ position: 'relative' }}>
        {uniqueUserData.map((post, index) => {
          // console.log(post);

          if (post?.creator?.username?.includes('null')) { 
            return '';
          } else {
            return (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.5rem',
                  
                }}
              >
                <img
                  src={
                    post?.creator?.profilePictureURL !== null
                      ? post?.creator?.profilePictureURL
                      : duummyAvatar
                  }
                  alt="proflie"
                  className="single-vesti-post-img"
                />

                <div style={{ fontWeight: 500 }}>
                  {' '}
                  {post?.creator?.firstName} {post?.creator?.lastName}
                </div>
              </div>
              {/* <TbMessage2/> */}
              {/* <TbMessage2 onClick={() => handleIconClick(post?.creator?.id, `${post?.creator?.firstName} ${post?.creator?.lastName}`, post?.creator?.profilePictureURL)} style={{width: '24px', height: '24px'}} /> */}
              {post?.userId ===  props.userD?.id? '' : <TbMessage2 onClick={() => handleIconClick(post?.creator?.id, `${post?.creator?.firstName} ${post?.creator?.lastName}`, post?.creator?.profilePictureURL)} className='memMesIcon' /> }
              </div>
            );
          }
        })}
      </div>
     
    </div>
  );
};



export default GroupMembers;
