import axios from 'axios';
import { getSession } from 'appRedux/store/cookies';
import _ from 'lodash';
import { navigate } from '@reach/router';

const settings = {
  baseURL: 'https://api.wevesti.com/api/v1',
  // baseURL: 'https://syca-staging.wevesti.com/api/v1',
  // baseURL:
  //   'http://syca-app-backend.eba-pe3mzmfm.us-east-1.elasticbeanstalk.com/api/v1',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json,text/plain,*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  },
};

const settingsTwo = {
  //baseURL: 'https://api.wevesti.com/api/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json,text/plain,*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  },
};

export const walletsService = {
  // baseURL: 'https://multicurrencyapi.wevesti.com/api/v1',
  // baseURL: 'https://wallet-staging.wevesti.com/api/v1',
  baseURL: 'https://multicurrencyapi.wevesti.com/api/v1',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json,text/plain,*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'wallet-secret': 'ec1607f49afeb2d949d49d80ce0ab6',
  },
};

export const pathwayService = {
  // baseURL:
  //   'http://vesti-pathways-prod.eba-2uik8sdw.us-east-1.elasticbeanstalk.com/api/v1',
  baseURL: 'https://pathwayapi.wevesti.com/api/v1',
  // baseURL: 'https://pathway-staging.wevesti.com/api/v1/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json,text/plain,*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  },
};

const instance = axios.create(settings);
const instanceTwo = axios.create(settingsTwo);
const walletInstance = axios.create(walletsService);
const pathwayInstance = axios.create(pathwayService);

export const token = getSession();
if (token != null && !_.isEmpty(token)) {
  instance.defaults.headers.common.Authorization = `Bearer ${getSession()}`;
  instanceTwo.defaults.headers.common.Authorization = `Bearer ${getSession()}`;
}

var rendInstance = value => {
  switch (value) {
    case 'stripe':
      return instanceTwo;
    case 'wallet':
      return walletInstance;
    case 'pathway':
      return pathwayInstance;

    default:
      return instance;
  }
};

export default {
  get(url, request, type) {
    return rendInstance(type ? type : '')
      .get(url, request)
      .then(response => Promise.resolve(response))
      .catch(error => {
        !window.location.href.includes('/auth') &&
          (error.response.data.message.includes(
            'Session expired, please login again',
          ) ||
            error.response.data.message.includes(
              'You need to login in order to access this.',
            ));
        // && navigate('/auth');
        return Promise.reject(error);
      });
  },
  post(url, request, type) {
    return rendInstance(type ? type : '')
      .post(url, request)
      .then(response => Promise.resolve(response))
      .catch(error => {
        error.response.data.message.includes(
          'Session expired, please login again',
        ) && navigate('/auth');
        if (error.response) {
          return Promise.reject(error.response);
        }
        return Promise.reject(error);
      });
  },
  put(url, request, type) {
    return rendInstance(type ? type : '')
      .put(url, request)
      .then(response => Promise.resolve(response))
      .catch(error => {
        error.response.data.message.includes(
          'Session expired, please login again',
        ) && navigate('/auth');
        return Promise.reject(error);
      });
  },
  patch(url, request, type) {
    return rendInstance(type ? type : '')
      .patch(url, request)
      .then(response => Promise.resolve(response))
      .catch(error => {
        error.response.data.message.includes(
          'Session expired, please login again',
        ) && navigate('/auth');
        return Promise.reject(error);
      });
  },
  delete(url, request) {
    return instance
      .delete(url, request)
      .then(response => Promise.resolve(response))
      .catch(error => {
        error.response.data.message.includes(
          'Session expired, please login again',
        ) && navigate('/auth');
        return Promise.reject(error);
      });
  },
};

export const setToken = token => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  instanceTwo.defaults.headers.common.Authorization = `Bearer ${token}`;
};
