import React from 'react';
import './feedsChat.css';
import { ChatList } from './ChatList';
import { OtherGroupsCom } from './OtherGroupsCom';

const FeedsChat = props => {
  
  return (
    <div className='feedsChatContainer'>
      <div
        className=" rounded-md"
        style={{
          background: '#F6F7FF',
          width: '100%',
          height: '95vh',
          position: 'relative',
        }}
      >
        <div
          className=""
          style={{
            background: '#F6F7F9',
            // background: 'pink',
            borderRadius: '10px',
            width: '100%',
            overflowY: 'scroll',
            height: '100%',
          }}
        >
          <div style={{ padding: '6%' }}>
            <div>
              <ChatList />
            </div>
            
            <div className="" style={{ marginTop: '2rem' }}>
              <OtherGroupsCom/>          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedsChat;
